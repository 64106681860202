<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-card v-for="job in jobs" class="ma-3" :key="job.id">
        <v-row class="ma-2 pa-0">
          <v-col cols="5" class="mt-4">
            {{ job.name }}
          </v-col>
          <v-col cols="4" v-if="job.name == 'azureSchedule'">
            <p>
              Wykonano: {{azureScheduleStatus.sent}}
            </p>
           <p>
             Do wykonania: {{azureScheduleStatus.inQueue}}
           </p>
          </v-col>
          <v-col cols="3">
            <v-switch v-model="job.active" @change="setJobState(job.name, job.active)" label="Aktywny"/>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { crono } from 'vue-crono'
import JobService from '@/services/JobService'
export default {
  name: 'Jobs',
  data() {
    return {
      jobs: [],
      azureScheduleStatus: {}
    }
  },
  cron:{
    time: 5000,
    method: 'refreshJobs'
  },
  mixins: [crono],
  mounted() {
    this.refreshJobs()
  },
  methods: {
    refreshJobs () {
      JobService.getAllJobs().then(async response => {
        this.jobs = response.data.data
      }).catch(e => {
        console.log(e)
      })
      JobService.getAzureScheduleStats().then(async response => {
        this.azureScheduleStatus = response.data.data
      }).catch(e => {
        console.log(e)
      })

    },
    setJobState(jobName, jobStatus) {
      console.log('Switch', jobName, jobStatus)
      JobService.setAzureScheduleStatus(jobName, jobStatus).then(async response => {
        this.azureScheduleStatus = response.data.data
      }).catch(e => {
        console.log(e)
      })
    }
  }
}
</script>

<style scoped>

</style>
