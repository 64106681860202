import axios from 'axios'
import helpers from "@/utils/helpers";
const baseURL = helpers.genBaseURL()

const apiClient = axios.create({
  baseURL: baseURL + '/api/v1',
  withCredentials: true,
  headers: {
    Authorization: 'Bearer ts_tok',
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
export default {
  getAllJobs() {
    return apiClient.get(`/jobs`)
  },
  setAzureScheduleStatus(jobName, jobStatus) {
    return apiClient.get(`/jobs/${jobName}/${jobStatus}`)
  },
  getAzureScheduleStats() {
    return apiClient.get(`/sendgrid/azureScheduleStats`)
  }
}
